import Styles from "./css/styles.module.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

gsap.registerPlugin(ScrollTrigger);

const Dongle = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  useEffect(() => {
    gsap.from("#dongle #dongle-1", {
      scrollTrigger: {
        trigger: "#dongle-1",
        start: "top 90%",
        end: "bottom bottom",
        toggleActions: "restart complete reverse reset",
      },
      y: 100,
      duration: 1,
      opacity: 0,
      ease: "ease-in",
    });
    gsap.from("#dongle #dongle-2", {
      scrollTrigger: {
        trigger: "#dongle-2",
        start: "top 90%",
        end: "bottom 95%",
        toggleActions: "restart complete reverse reset",
      },
      y: 100,
      duration: 1,
      opacity: 0,
      ease: "ease-in",
    });
    gsap.from("#dongle #dongle-3", {
      scrollTrigger: {
        trigger: "#dongle-3",
        start: "top 90%",
        end: "bottom 95%",
        toggleActions: "restart complete reverse reset",
      },
      y: 100,
      duration: 1,
      opacity: 0,
      ease: "ease-out",
    });
    if (isMobile) {
      gsap.from("#dongle #dongle-5", {
        scrollTrigger: {
          trigger: "#dongle-5",
          start: "top 90%",
          end: "bottom 95%",
          toggleActions: "restart complete reverse reset",
        },
        y: 100,
        duration: 1,
        opacity: 0,
        ease: "ease-in",
      });
    } else {
      gsap.from("#dongle #dongle-4", {
        scrollTrigger: {
          trigger: "#dongle-4",
          start: "top 90%",
          end: "bottom bottom",
          toggleActions: "restart complete reverse reset",
        },
        x: 100,
        duration: 2,
        opacity: 0,
        ease: "elastic",
      });
    }
  }, [isMobile]);
  return (
    <section className={Styles.dongle}>
      <div className="my-container">
        <div className={Styles.inner} id="dongle">
          <div className={`${Styles.imgCon} ${Styles.hide}`}>
            <img src="/images/dongle.png" alt="Dongle" id="dongle-5" />
          </div>
          <div className={Styles.content}>
            <h1 id="dongle-1">Get the FixBot dongle!</h1>
            <p id="dongle-2">
              Get the FixBot dongle and begin your premium experience.
            </p>
            <a id="dongle-3" href="https://paystack.com/pay/fixbotday1">
              Get it now!
            </a>
          </div>
          <div className={`${Styles.imgCon} ${Styles.show}`}>
            <img src="/images/dongle.png" alt="Dongle" id="dongle-4" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dongle;
