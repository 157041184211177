import React from "react";
import Styles from "./css/styles.module.css";

const Notfound = () => {
  return (
    <div className={Styles.error}>
      <img src="/images/404.jpg" alt="Error" />
    </div>
  );
};

export default Notfound;
