import { Link } from "react-router-dom"
import Styles from "./css/styles.module.css"
import { useMediaQuery } from 'react-responsive';
import { gsap} from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useEffect } from "react";
import Video from "./fixbot.mp4";
gsap.registerPlugin(ScrollTrigger);
const How = () => {
    const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
    useEffect(() => {
        gsap.from("#how #text-1", {
            scrollTrigger: {trigger:"#text-1", start:"top 90%", end:"bottom bottom" , toggleActions: "restart complete reverse reset"},
            y: 100, duration:1, opacity:0, ease: "ease-in"
        })
        gsap.from("#how #text-2", {
            scrollTrigger: {trigger:"#text-2", start:"top 90%", end:"bottom 95%", toggleActions: "restart complete reverse reset"},
            y: 100, duration:1, opacity:0, ease: "ease-in"
        })
        gsap.from("#how #how-link", {
            scrollTrigger: {trigger:"#how-link", start:"top 90%", end:"bottom 95%", toggleActions: "restart complete reverse reset"},
            y: 100, duration:1, opacity:0, ease: "ease-in"
        })
        if(isMobile) {
            gsap.from("#how #vid-1", {
                scrollTrigger: {trigger:"#vid-1", start:"top 90%", end:"bottom 95%", toggleActions: "restart complete reverse reset"},
                y: 100, duration:1, opacity:0, ease: "ease-in"
            })
        }else {
            gsap.from("#how #vid-2", {
                scrollTrigger: {trigger:"#vid-2", start:"top 90%", end:"bottom bottom", toggleActions: "restart complete reverse reset"},
                x: 100, duration:2, opacity:0, ease: "elastic"
            })
        }
    }, [isMobile]);
    return (
        <section className={Styles.how}>
            <div className="my-container">
                <div className={Styles.inner} id="how">
                    <div className={`${Styles.vid} ${Styles.hide}`} id="vid-1">
                        <video src="/fixbot.mp4" controls />
                    </div>
                    <div className={Styles.content}>
                        <h1 id="text-1">How FixBot works.</h1>
                        <p id="text-2">Our device monitors the overall performance of your vehicle and sends alerts via SMS, chatbot as well as mobile app and web applications.</p>
                        <Link id="how-link" to="/">Learn more</Link>
                    </div>
                    <div className={`${Styles.vid} ${Styles.show}`} id="vid-2">
                        <video src={Video} controls />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default How
