import Swiper from '../Swiper/Swiper'
import Styles from './css/styles.module.css'
import { gsap} from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useEffect } from "react";
gsap.registerPlugin(ScrollTrigger);
const Testimonies = () => {
    useEffect(() => {
        gsap.from("#testimony #testimony-1", {
            scrollTrigger: {trigger:"#testimony-1", start:"top 90%", end:"bottom bottom" , toggleActions: "restart complete reverse reset"},
            y: 100, duration:1, opacity:0, ease: "ease-in"
        })
        gsap.from("#testimony #testimony-2", {
            scrollTrigger: {trigger:"#testimony-2", start:"top 90%", end:"bottom 90%", toggleActions: "restart complete reverse reset"},
            y: 100, duration:1, opacity:0, ease: "ease-in"
        })
    }, []);
    return (
        <section className={Styles.testimonies_}>
            <div className="my-container">
                <div className={Styles.inner_} id='testimony'>
                    <h1 id='testimony-1'>Don’t just take our word for it...</h1>
                    <p id='testimony-2'>See what some of our best clients have to say </p>
                    <Swiper id='testimony-3'/>
                </div>
            </div>
        </section>
    )
}

export default Testimonies
