import { useState, useEffect, useRef } from "react";
import { useMediaQuery } from 'react-responsive';
import {Link} from "react-router-dom";
import { useLocation } from 'react-router-dom'
import "./css/styles.css"
import { gsap } from "gsap";

const Index = () => {
    const location = useLocation();
    const [mobileNav, setmobileNav] = useState(false);
    const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
    const handleMobileNav = () => {
        if(isMobile){
            setmobileNav(!mobileNav);
        }
    }
    const el = useRef();
    const q = gsap.utils.selector(el);
    const tl = useRef();

    useEffect(() => {
        if(!isMobile){
            tl.current = gsap.timeline()
            .from(q("li"), {
                stagger: 0.1, opacity: 0, delay:1, duration:1.5
            });
        }
        
    });
   
    return (
        <nav >
           <div className="my-container inner">
            <div className="logo">
                <Link to = "/"><img src="/images/newlogo.png" alt="logo"/></Link>
            </div>
            <ul ref={el} className={mobileNav ? "nav-active" : "nav-links"}>
                <li><Link onClick={handleMobileNav} to = "/" className ={location.pathname === "/" ? "activate" : ""}>Home</Link></li>
                <li><a href="https://blog.myfixbot.com/" onClick={handleMobileNav} className={location.pathname === "/services" ? "activate" : ""} >Blog</a></li>
                <li><a href="https://dashboard.myfixbot.com/login"  onClick={handleMobileNav} >Sign In</a></li>
                <li className="request"><a href="https://dashboard.myfixbot.com/signup"  onClick={handleMobileNav} >Sign Up</a></li>
            </ul>
            <div className="frame">
                <a href="https://dashboard.myfixbot.com/signup"  className ="pin">Sign Up</a>
                <div className={mobileNav ? "bro" : "burger"}  onClick={handleMobileNav}>
                    <div className="line0"></div>
                    <div className="line1"></div>
                    <div className="line2"></div>
                </div>
            </div>
           
           </div>
        </nav>
    )
}

export default Index;
