import React, { useState, useEffect } from "react";
import Layout from "../Layout/Layout";
import {
  OrderedList,
  List,
  ListItem,
  ListIcon,
  Box,
  Stack,
  Heading,
  Text,
  ChakraProvider,
} from "@chakra-ui/react";
import { MdCheckCircle } from "react-icons/md";

function PrivacyPolicy() {
  return (
    <ChakraProvider>
      <Box className="privacy-box">
        <Layout>
          <Box
            minH="500px"
            py={4}
            w="full"
            bg="#374A65"
            display="grid"
            placeItems="center"
          >
            <Box px={10}>
              <Heading fontSize={{ base: "1rem", md: "3rem" }} color="#fff">
                FixBot Privacy Policy
              </Heading>
            </Box>
          </Box>
          <Box
            bg="#374A65"
            color="#fff"
            borderTop="1px solid rgba(255,255,255, 0.3)"
            py={10}
          >
            <Text textAlign="center">Effective: June 17, 2022</Text>
          </Box>
          <Box bg="#fff" minH="900px" py="100px">
            <Stack spacing="30px" w="90%" maxW="1100px" mx="auto" color="#777">
              <Stack spacing={"20px"}>
                <Heading
                  textDecoration="underline"
                  color="#3374a6"
                  fontSize="1.6rem"
                >
                  1. Introduction
                </Heading>
                <Stack>
                  <Text>Welcome to FixBot Technologies Limited.</Text>
                  <Text>
                    FixBot Technologies Limited (“us”, “we”, or “our”) operates{" "}
                    <a
                      href="https://www.myfixbot.com"
                      target="_blank"
                      without
                      rel="noreferrer"
                    >
                      https://www.myfixbot.com
                    </a>{" "}
                    and FixBot mobile application (hereinafter referred to as
                    “Service”).
                  </Text>
                  <Text>
                    Our Privacy Policy governs your visit to
                    https://www.myfixbot.com and FixBot mobile application, and
                    explains how we collect, safeguard and disclose information
                    that results from your use of our Service.
                  </Text>
                  <Text>
                    We use your data to provide and improve Service. By using
                    Service, you agree to the collection and use of information
                    in accordance with this policy. Unless otherwise defined in
                    this Privacy Policy, the terms used in this Privacy Policy
                    have the same meanings as in our Terms and Conditions.
                  </Text>
                  <Text>
                    Our Terms and Conditions (“Terms”) govern all use of our
                    Service and together with the Privacy Policy constitutes
                    your agreement with us (“agreement”).
                  </Text>
                </Stack>
              </Stack>

              <Stack spacing={"20px"}>
                <Heading
                  textDecoration="underline"
                  color="#3374a6"
                  fontSize="1.6rem"
                >
                  2. Definition
                </Heading>
                <Stack>
                  <Text>
                    SERVICE means the https://www.myfixbot.com website and
                    FixBot mobile application operated by FixBot Technologies
                    Limited.
                  </Text>
                  <Text>
                    PERSONAL DATA means data about a living individual who can
                    be identified from those data (or from those and other
                    information either in our possession or likely to come into
                    our possession).
                  </Text>
                  <Text>
                    USAGE DATA is data collected automatically either generated
                    by the use of Service or from Service infrastructure itself
                    (for example, the duration of a page visit).
                  </Text>
                  <Text>
                    COOKIES are small files stored on your device (computer or
                    mobile device).
                  </Text>
                  <Text>
                    DATA CONTROLLER means a natural or legal person who (either
                    alone or jointly or in common with other persons) determines
                    the purposes for which and the manner in which any personal
                    data are, or are to be, processed. For the purpose of this
                    Privacy Policy, we are a Data Controller of your data.
                  </Text>
                  <Text>
                    DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or
                    legal person who processes the data on behalf of the Data
                    Controller. We may use the services of various Service
                    Providers in order to process your data more effectively.
                  </Text>
                  <Text>
                    DATA SUBJECT is any living individual who is the subject of
                    Personal Data.
                  </Text>
                  <Text>
                    THE USER is the individual using our Service. The User
                    corresponds to the Data Subject, who is the subject of
                    Personal Data.
                  </Text>
                </Stack>
              </Stack>

              <Stack spacing={"20px"}>
                <Heading
                  textDecoration="underline"
                  color="#3374a6"
                  fontSize="1.6rem"
                >
                  3. Information Collection and Use
                </Heading>
                <Stack>
                  <Text>
                    We collect several different types of information for
                    various purposes to provide and improve our Service to you.
                  </Text>
                </Stack>
              </Stack>

              <Stack spacing={"20px"}>
                <Heading
                  textDecoration="underline"
                  color="#3374a6"
                  fontSize="1.6rem"
                >
                  4. Types of Data Collected
                </Heading>
                <Stack>
                  <Text>
                    <Text color="gray.700" fontWeight="bold">
                      Personal Data
                    </Text>
                    While using our Service, we may ask you to provide us with
                    certain personally identifiable information that can be used
                    to contact or identify you (“Personal Data”). Personally
                    identifiable information may include, but is not limited to:
                  </Text>
                  <List spacing={3}>
                    <ListItem display="flex" alignItems="center">
                      <ListIcon as={MdCheckCircle} color="green.500" />
                      Email address
                    </ListItem>
                    <ListItem display="flex" alignItems="center">
                      <ListIcon as={MdCheckCircle} color="green.500" />
                      First name and last name suscipit
                    </ListItem>
                    <ListItem display="flex" alignItems="center">
                      <ListIcon as={MdCheckCircle} color="green.500" />
                      Phone number
                    </ListItem>
                    <ListItem display="flex" alignItems="center">
                      <ListIcon as={MdCheckCircle} color="green.500" />
                      Address, State, Province, ZIP/Postal code, City
                    </ListItem>
                    <ListItem display="flex" alignItems="center">
                      <ListIcon as={MdCheckCircle} color="green.500" />
                      Cookies and Usage Data
                    </ListItem>
                    {/* You can also use custom icons from react-icons */}
                  </List>
                  <Text>
                    We may use your Personal Data to contact you with
                    newsletters, marketing or promotional materials and other
                    information that may be of interest to you. You may opt out
                    of receiving any, or all, of these communications from us by
                    following the unsubscribe link or by emailing at
                    support@myfixbot.com
                  </Text>
                  <Text>
                    <Text color="gray.700" fontWeight="bold">
                      Usage Data
                    </Text>
                    We may also collect information that your browser sends
                    whenever you visit our Service or when you access Service by
                    or through a mobile device (“Usage Data”).
                  </Text>
                  <Text>
                    This Usage Data may include information such as your
                    computer's Internet Protocol address (e.g. IP address),
                    browser type, browser version, the pages of our Service that
                    you visit, the time and date of your visit, the time spent
                    on those pages, unique device identifiers and other
                    diagnostic data.
                  </Text>
                  <Text>
                    When you access Service with a mobile device, this Usage
                    Data may include information such as the type of mobile
                    device you use, your mobile device unique ID, the IP address
                    of your mobile device, your mobile operating system, the
                    type of mobile Internet browser you use, unique device
                    identifiers and other diagnostic data.
                  </Text>
                  <Text>
                    <Text color="gray.700" fontWeight="bold">
                      Location Data
                    </Text>
                    We may use and store information about your location if you
                    give us permission to do so (“Location Data”). We use this
                    data to provide features of our Service, to improve and
                    customize our Service.
                  </Text>
                  <Text>
                    You can enable or disable location services when you use our
                    Service at any time by way of your device settings.
                  </Text>

                  <Text>
                    <Text color="gray.700" fontWeight="bold">
                      Tracking Cookies Data
                    </Text>
                    We use cookies and similar tracking technologies to track
                    the activity on our Service and we hold certain information
                  </Text>
                  <Text>
                    Cookies are files with a small amount of data which may
                    include an anonymous unique identifier. Cookies are sent to
                    your browser from a website and stored on your device. Other
                    tracking technologies are also used such as beacons, tags
                    and scripts to collect and track information and to improve
                    and analyze our Service.
                  </Text>
                  <Text>
                    You can instruct your browser to refuse all cookies or to
                    indicate when a cookie is being sent. However, if you do not
                    accept cookies, you may not be able to use some portions of
                    our Service.
                  </Text>
                  <Text>Examples of Cookies we use:</Text>
                  <OrderedList spacing={3} pl={4}>
                    <ListItem>
                      <b>Session Cookies:</b> We use Session Cookies to operate
                      our Service.
                    </ListItem>
                    <ListItem>
                      <b>Preference Cookies:</b> We use Preference Cookies to
                      remember your preferences and various settings. First name
                    </ListItem>
                    <ListItem>
                      <b>Security Cookies:</b> We use Security Cookies for
                      security purposes.
                    </ListItem>
                    <ListItem>
                      <b>Advertising Cookies:</b> Advertising Cookies are used
                      to serve you with advertisements that may be relevant to
                      you and your interests.
                    </ListItem>
                  </OrderedList>
                  <Text>
                    <Text color="gray.700" fontWeight="bold">
                      Other Data
                    </Text>
                    While using our Service, we may also collect the following
                    information: sex, age, date of birth, place of birth,
                    passport details, citizenship, registration at place of
                    residence and actual address, telephone number (work,
                    mobile), details of documents on education, qualification,
                    professional training, employment agreements, non-disclosure
                    agreements, information on bonuses and compensation,
                    information on marital status, family members, social
                    security (or other taxpayer identification) number, office
                    location and other data.
                  </Text>
                </Stack>
              </Stack>

              <Stack spacing={"20px"}>
                <Heading
                  textDecoration="underline"
                  color="#3374a6"
                  fontSize="1.6rem"
                >
                  5. Use of Data
                </Heading>
                <Stack>
                  <Text>
                    FixBot Technologies Limited uses the collected data for
                    various purposes:
                  </Text>
                  <OrderedList spacing={3} pl={4}>
                    <ListItem>to provide and maintain our Service</ListItem>
                    <ListItem>
                      to notify you about changes to our Service;
                    </ListItem>
                    <ListItem>
                      to allow you to participate in interactive features of our
                      Service when you choose to do so;
                    </ListItem>
                    <ListItem>to provide customer support;</ListItem>
                    <ListItem>
                      to gather analysis or valuable information so that we can
                      improve our Service;
                    </ListItem>
                    <ListItem>to monitor the usage of our Service;</ListItem>
                    <ListItem>
                      to detect, prevent and address technical issues;
                    </ListItem>
                    <ListItem>
                      to fulfill any other purpose for which you provide it;
                    </ListItem>
                    <ListItem>
                      to carry out our obligations and enforce our rights
                      arising from any contracts entered into between you and
                      us, including for billing and collection
                    </ListItem>
                    <ListItem>
                      to provide you with notices about your account and/or
                      subscription, including expiration and renewal notices,
                      email-instructions, etc.;
                    </ListItem>
                    <ListItem>
                      to provide you with news, special offers and general
                      information about other goods, services and events which
                      we offer that are similar to those that you have already
                      purchased or enquired about unless you have opted not to
                      receive such information
                    </ListItem>
                    <ListItem>
                      in any other way we may describe when you provide the
                      information;
                    </ListItem>
                    <ListItem>
                      for any other purpose with your consent.
                    </ListItem>
                  </OrderedList>
                </Stack>
              </Stack>

              <Stack spacing={"20px"}>
                <Heading
                  textDecoration="underline"
                  color="#3374a6"
                  fontSize="1.6rem"
                >
                  6. Retention of Data
                </Heading>
                <Stack>
                  <Text>
                    We will retain your Personal Data only for as long as is
                    necessary for the purposes set out in this Privacy Policy.
                    We will retain and use your Personal Data to the extent
                    necessary to comply with our legal obligations (for example,
                    if we are required to retain your data to comply with
                    applicable laws), resolve disputes, and enforce our legal
                    agreements and policies.
                  </Text>
                  <Text>
                    We will also retain Usage Data for internal analysis
                    purposes. Usage Data is generally retained for a shorter
                    period, except when this data is used to strengthen the
                    security or to improve the functionality of our Service, or
                    we are legally obligated to retain this data for longer time
                    periods
                  </Text>
                </Stack>
              </Stack>

              <Stack spacing={"20px"}>
                <Heading
                  textDecoration="underline"
                  color="#3374a6"
                  fontSize="1.6rem"
                >
                  7. Transfer of Data
                </Heading>
                <Stack>
                  <Text>
                    Your information, including Personal Data, may be
                    transferred to – and maintained on – computers located
                    outside of your state, province, country or other
                    governmental jurisdiction where the data protection laws may
                    differ from those of your jurisdiction.
                  </Text>
                  <Text>
                    If you are located outside United States and choose to
                    provide information to us, please note that we transfer the
                    data, including Personal Data, to United States and process
                    it there.
                  </Text>
                  <Text>
                    Your consent to this Privacy Policy followed by your
                    submission of such information represents your agreement to
                    that transfer.
                  </Text>
                  <Text>
                    FixBot Technologies Limited will take all the steps
                    reasonably necessary to ensure that your data is treated
                    securely and in accordance with this Privacy Policy and no
                    transfer of your Personal Data will take place to an
                    organisation or a country unless there are adequate controls
                    in place including the security of your data and other
                    personal information.
                  </Text>
                </Stack>
              </Stack>

              <Stack spacing={"20px"}>
                <Heading
                  textDecoration="underline"
                  color="#3374a6"
                  fontSize="1.6rem"
                >
                  8. Disclosure of Data
                </Heading>
                <Stack>
                  <Text>
                    We may disclose personal information that we collect, or you
                    provide:
                  </Text>
                  <Text>
                    <Text fontWeight="bold" color="gray.700">
                      (a) Business Transaction.
                    </Text>
                    If we or our subsidiaries are involved in a merger,
                    acquisition or asset sale, your Personal Data may be
                    transferred
                  </Text>

                  <Text>
                    <Text fontWeight="bold" color="gray.700">
                      (b) Other cases. We may disclose your information also:
                    </Text>
                    <OrderedList mt={3} spacing={3} pl={4} styleType="unset">
                      <ListItem>to our subsidiaries and affiliates;</ListItem>
                      <ListItem>
                        to contractors, service providers, and other third
                        parties we use to support our business;
                      </ListItem>
                      <ListItem>
                        to fulfill the purpose for which you provide it;
                      </ListItem>
                      <ListItem>
                        for the purpose of including your company’s logo on our
                        website;
                      </ListItem>
                      <ListItem>with your consent in any other cases</ListItem>
                    </OrderedList>
                  </Text>
                </Stack>
              </Stack>

              <Stack spacing={"20px"}>
                <Heading
                  textDecoration="underline"
                  color="#3374a6"
                  fontSize="1.6rem"
                >
                  9. Security of Data
                </Heading>
                <Stack>
                  <Text>
                    The security of your data is important to us but remember
                    that no method of transmission over the Internet or method
                    of electronic storage is 100% secure. While we strive to use
                    commercially acceptable means to protect your Personal Data,
                    we cannot guarantee its absolute security.
                  </Text>
                </Stack>
              </Stack>

              <Stack spacing={"20px"}>
                <Heading
                  textDecoration="underline"
                  color="#3374a6"
                  fontSize="1.6rem"
                >
                  10. Your Data Protection Rights Under General Data Protection
                  Regulation (GDPR)
                </Heading>
                <Stack>
                  <Text>
                    If you are a resident of the European Union (EU) and
                    European Economic Area (EEA), you have certain data
                    protection rights, covered by GDPR. – See more at
                    <a
                      href="https://eur-lex.europa.eu/eli/reg/2016/679/oj"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://eur-lex.europa.eu/eli/reg/2016/679/oj
                    </a>
                  </Text>
                  <Text>
                    We aim to take reasonable steps to allow you to correct,
                    amend, delete, or limit the use of your Personal Data.
                  </Text>
                  <Text>
                    If you wish to be informed what Personal Data we hold about
                    you and if you want it to be removed from our systems,
                    please email us at{" "}
                    <a href="mailto:support@myfixbot.com">
                      support@myfixbot.com.
                    </a>
                  </Text>
                  <Text>
                    In certain circumstances, you have the following data
                    protection rights:
                  </Text>
                  <OrderedList mt={3} spacing={3} pl={4} styleType="unset">
                    <ListItem>
                      the right to access, update or to delete the information
                      we have on you;
                    </ListItem>
                    <ListItem>
                      the right of rectification. You have the right to have
                      your information rectified if that information is
                      inaccurate or incomplete;
                    </ListItem>
                    <ListItem>
                      the right to object. You have the right to object to our
                      processing of your Personal Data;
                    </ListItem>
                    <ListItem>
                      the right of restriction. You have the right to request
                      that we restrict the processing of your personal
                      information;
                    </ListItem>
                    <ListItem>
                      the right to data portability. You have the right to be
                      provided with a copy of your Personal Data in a
                      structured, machine-readable and commonly used format;
                    </ListItem>
                    <ListItem>
                      the right to withdraw consent. You also have the right to
                      withdraw your consent at any time where we rely on your
                      consent to process your personal information
                    </ListItem>
                  </OrderedList>
                  <Text>
                    Please note that we may ask you to verify your identity
                    before responding to such requests. Please note, we may not
                    able to provide Service without some necessary data.
                  </Text>
                  <Text>
                    You have the right to complain to a Data Protection
                    Authority about our collection and use of your Personal
                    Data. For more information, please contact your local data
                    protection authority in the European Economic Area (EEA).
                  </Text>
                </Stack>
              </Stack>

              <Stack spacing={"20px"}>
                <Heading
                  textDecoration="underline"
                  color="#3374a6"
                  fontSize="1.6rem"
                >
                  11. Service Providers
                </Heading>
                <Stack>
                  <Text>
                    We may employ third party companies and individuals to
                    facilitate our Service (“Service Providers”), provide
                    Service on our behalf, perform Service-related services or
                    assist us in analysing how our Service is used
                  </Text>
                  <Text>
                    These third parties have access to your Personal Data only
                    to perform these tasks on our behalf and are obligated not
                    to disclose or use it for any other purpose.
                  </Text>
                </Stack>
              </Stack>

              <Stack spacing={"20px"}>
                <Heading
                  textDecoration="underline"
                  color="#3374a6"
                  fontSize="1.6rem"
                >
                  12. Analytics
                </Heading>
                <Stack>
                  <Text>
                    We may use third-party Service Providers to monitor and
                    analyze the use of our Service.
                  </Text>
                  <Text>
                    <Text fontWeight="bold" color="gray.700">
                      Google Analytics
                    </Text>
                    Google Analytics is a web analytics service offered by
                    Google that tracks and reports website traffic. Google uses
                    the data collected to track and monitor the use of our
                    Service. This data is shared with other Google services.
                    Google may use the collected data to contextualise and
                    personalise the ads of its own advertising network.
                  </Text>
                  <Text>
                    For more information on the privacy practices of Google,
                    please visit the Google Privacy Terms web page:
                    <a
                      href="https://policies.google.com/privacy?hl=en"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      https://policies.google.com/privacy?hl=en
                    </a>
                  </Text>
                  <Text>
                    We also encourage you to review the Google's policy for
                    safeguarding your data:
                    <a
                      href="https://support.google.com/analytics/answer/6004245"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      https://support.google.com/analytics/answer/6004245
                    </a>
                  </Text>
                  <Text>
                    <Text fontWeight="bold" color="gray.700">
                      Cloudflare analytics
                    </Text>
                    Cloudflare analytics Cloudflare analytics is a web analytics
                    service operated by Cloudflare Inc. Read the Privacy Policy
                    here:
                    <a
                      href="https://www.cloudflare.com/privacypolicy/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      https://www.cloudflare.com/privacypolicy/
                    </a>
                  </Text>

                  <Text>
                    <Text fontWeight="bold" color="gray.700">
                      Segment.io
                    </Text>
                    Segment.io is a web traffic analysis tool. You can read the
                    Privacy Policy for Segment.io here:
                    <a
                      href="https://www.cloudflare.com/privacypolicy/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      https://segment.com/legal/privacy/
                    </a>
                  </Text>
                  <Text>
                    <Text fontWeight="bold" color="gray.700">
                      Mixpanel
                    </Text>
                    Mixpanel is provided by Mixpanel Inc
                  </Text>
                  <Text>
                    You can prevent Mixpanel from using your information for
                    analytics purposes by opting-out. To opt-out of Mixpanel
                    service, please visit this page:
                    <a
                      href="https://mixpanel.com/optout/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      https://mixpanel.com/optout/
                    </a>
                  </Text>
                  <Text>
                    For more information on what type of information Mixpanel
                    collects, please visit the Terms of Use page of Mixpanel:
                    <a
                      href="https://mixpanel.com/terms/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      https://mixpanel.com/terms/
                    </a>
                  </Text>
                </Stack>
              </Stack>

              <Stack spacing={"20px"}>
                <Heading
                  textDecoration="underline"
                  color="#3374a6"
                  fontSize="1.6rem"
                >
                  13. CI/CD tools
                </Heading>
                <Stack>
                  <Text>
                    We may use third-party Service Providers to automate the
                    development process of our Service.
                  </Text>
                  <Text>GitHub is provided by GitHub, Inc.</Text>
                  <Text>
                    GitHub is a development platform to host and review code,
                    manage projects, and build software.
                  </Text>
                  <Text>
                    For more information on what data GitHub collects for what
                    purpose and how the protection of the data is ensured,
                    please visit GitHub Privacy Policy page:
                    <a
                      href="https://help.github.com/en/articles/github-privacy-statement"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      https://help.github.com/en/articles/github-privacy-statement
                    </a>
                  </Text>
                  <Text>
                    <Text fontWeight="bold" color="gray.700">
                      GitLab CI/CD
                    </Text>
                    GitLab CI/CD is provided by GitLab, Inc.
                  </Text>
                  <Text>
                    GitLab CI (Continuous Integration) service is a part of
                    GitLab that build and test the software whenever developer
                    pushes code to application.
                  </Text>
                  <Text>
                    GitLab CD (Continuous Deployment) is a software service that
                    places the changes of every code in the production which
                    results in every day deployment of production.
                  </Text>
                  <Text>
                    For more information on what data GitLab CI/CD collects for
                    what purpose and how the protection of the data is ensured,
                    please visit GitLab CI/CD Privacy Policy page:
                    <a
                      href="https://about.gitlab.com/privacy/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      https://about.gitlab.com/privacy/.
                    </a>
                  </Text>
                </Stack>
              </Stack>

              <Stack spacing={"20px"}>
                <Heading
                  textDecoration="underline"
                  color="#3374a6"
                  fontSize="1.6rem"
                >
                  14. Behavioral Remarketing
                </Heading>
                <Stack>
                  <Text>
                    FixBot Technologies Limited uses remarketing services to
                    advertise on third party websites to you after you visited
                    our Service. We and our third-party vendors use cookies to
                    inform, optimise and serve ads based on your past visits to
                    our Service.
                  </Text>
                  <Text>
                    <Text fontWeight="bold" color="gray.700">
                      Google Ads (AdWords)
                    </Text>
                    Google Ads (AdWords) remarketing service is provided by
                    Google Inc.
                  </Text>
                  <Text>
                    You can opt-out of Google Analytics for Display Advertising
                    and customize the Google Display Network ads by visiting the
                    Google Ads Settings page:
                    <a
                      href="http://www.google.com/settings/ads"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      http://www.google.com/settings/ads
                    </a>
                  </Text>

                  <Text>
                    Google also recommends installing the Google Analytics
                    Opt-out Browser Add-on –
                    <a
                      href="https://tools.google.com/dlpage/gaoptout"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      https://tools.google.com/dlpage/gaoptout{" "}
                    </a>
                    – for your web browser. Google Analytics Opt-out Browser
                    Add-on provides visitors with the ability to prevent their
                    data from being collected and used by Google Analytics.
                  </Text>
                  <Text>
                    For more information on the privacy practices of Google,
                    please visit the Google Privacy Terms web page:
                    <a
                      href="https://policies.google.com/privacy?hl=en"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      https://policies.google.com/privacy?hl=en
                    </a>
                  </Text>
                  <Text>
                    For more information on what data GitHub collects for what
                    purpose and how the protection of the data is ensured,
                    please visit GitHub Privacy Policy page:
                    <a
                      href="https://help.github.com/en/articles/github-privacy-statement"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      https://help.github.com/en/articles/github-privacy-statement
                    </a>
                  </Text>
                  <Text>
                    <Text fontWeight="bold" color="gray.700">
                      Facebook{" "}
                    </Text>
                    Facebook remarketing service is provided by Facebook Inc. on
                    which results in every day deployment of production.
                  </Text>
                  <Text>
                    You can learn more about interest-based advertising from
                    Facebook by visiting this page:
                    <a
                      href="https://www.facebook.com/help/164968693837950"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      https://www.facebook.com/help/164968693837950
                    </a>
                  </Text>

                  <Text>
                    To opt-out from Facebook's interest-based ads, follow these
                    instructions from Facebook:
                    <a
                      href="https://www.facebook.com/help/568137493302217"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      https://www.facebook.com/help/568137493302217
                    </a>
                  </Text>

                  <Text>
                    Facebook adheres to the Self-Regulatory Principles for
                    Online Behavioural Advertising established by the Digital
                    Advertising Alliance. You can also opt-out from Facebook and
                    other participating companies through the Digital
                    Advertising Alliance in the USA
                    <a
                      href="http://www.aboutads.info/choices/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      http://www.aboutads.info/choices/
                    </a>
                    , the Digital Advertising Alliance of Canada in Canada
                    <a
                      href="http://youradchoices.ca/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      http://youradchoices.ca/
                    </a>
                    or the European Interactive Digital Advertising Alliance in
                    Europe
                    <a
                      href="http://www.youronlinechoices.eu/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      http://www.youronlinechoices.eu/
                    </a>
                    or opt-out using your mobile device settings.
                  </Text>
                  <Text>
                    For more information on the privacy practices of Facebook,
                    please visit Facebook's Data Policy:
                    <a
                      href="https://www.facebook.com/privacy/explanation"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      https://www.facebook.com/privacy/explanation
                    </a>
                  </Text>
                </Stack>
              </Stack>

              <Stack spacing={"20px"}>
                <Heading
                  textDecoration="underline"
                  color="#3374a6"
                  fontSize="1.6rem"
                >
                  15. Payments
                </Heading>
                <Stack>
                  <Text>
                    We may provide paid products and/or services within Service.
                    In that case, we use third-party services for payment
                    processing (e.g. payment processors).
                  </Text>
                  <Text>
                    We will not store or collect your payment card details. That
                    information is provided directly to our third-party payment
                    processors whose use of your personal information is
                    governed by their Privacy Policy. These payment processors
                    adhere to the standards set by PCI-DSS as managed by the PCI
                    Security Standards Council, which is a joint effort of
                    brands like Visa, Mastercard, American Express and Discover.
                    PCI-DSS requirements help ensure the secure handling of
                    payment information
                  </Text>
                  <Text>The payment processors we work with are:</Text>

                  <Text>
                    <Text fontWeight="bold" color="gray.700">
                      PayPal or Braintree:
                    </Text>
                    Their Privacy Policy can be viewed at
                    <a
                      href="https://www.paypal.com/webapps/mpp/ua/privacy-full  "
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      https://www.paypal.com/webapps/mpp/ua/privacy-full
                    </a>
                  </Text>

                  <Text>
                    <Text fontWeight="bold" color="gray.700">
                      Google Play In-App Payments:
                    </Text>
                    Their Privacy Policy can be viewed at:
                    <a
                      href="https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&ldt=privacynotice&ldl=en"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&ldt=privacynotice&ldl=en{" "}
                    </a>
                    <a
                      href="https://policies.google.com/privacy?hl=en&gl=us/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      https://policies.google.com/privacy?hl=en&gl=us/.{" "}
                    </a>
                  </Text>
                </Stack>
              </Stack>

              <Stack spacing={"20px"}>
                <Heading
                  textDecoration="underline"
                  color="#3374a6"
                  fontSize="1.6rem"
                >
                  16. Links to Other Sites
                </Heading>
                <Stack>
                  <Text>
                    Our Service may contain links to other sites that are not
                    operated by us. If you click a third party link, you will be
                    directed to that third party's site. We strongly advise you
                    to review the Privacy Policy of every site you visit.
                  </Text>
                  <Text>
                    We have no control over and assume no responsibility for the
                    content, privacy policies or practices of any third party
                    sites or services.
                  </Text>
                </Stack>
              </Stack>

              <Stack spacing={"20px"}>
                <Heading
                  textDecoration="underline"
                  color="#3374a6"
                  fontSize="1.6rem"
                >
                  18. Changes to This Privacy Policy
                </Heading>
                <Stack>
                  <Text>
                    We may update our Privacy Policy from time to time. We will
                    notify you of any changes by posting the new Privacy Policy
                    on this page.
                  </Text>
                  <Text>
                    We will let you know via email and/or a prominent notice on
                    our Service, prior to the change becoming effective and
                    update “effective date” at the top of this Privacy Policy.
                  </Text>
                  <Text>
                    You are advised to review this Privacy Policy periodically
                    for any changes. Changes to this Privacy Policy are
                    effective when they are posted on this page.
                  </Text>
                </Stack>
              </Stack>

              <Stack spacing={"20px"}>
                <Heading
                  textDecoration="underline"
                  color="#3374a6"
                  fontSize="1.6rem"
                >
                  19. Contact Us
                </Heading>
                <Stack>
                  <Text>
                    If you have any questions about this Privacy Policy, please
                    contact us:
                  </Text>
                  <Text>By email: support@myfixbot.com.</Text>
                  <Text>
                    By visiting this page on our website:
                    <a
                      href="https://www.myfixbot.com/about-us"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      https://www.myfixbot.com/about-us.
                    </a>
                  </Text>
                  <Text>
                    By mail:
                    <a href="mailto:kunle@myfixbot.com">
                      {" "}
                      kunle@myfixbot.com.{" "}
                    </a>
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Layout>
      </Box>
    </ChakraProvider>
  );
}

{
  /* <Text>
                    For more information on the privacy practices of Google,
                    please visit the Google Privacy Terms web page:
                    <a
                      href="https://policies.google.com/privacy?hl=en"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      https://policies.google.com/privacy?hl=en
                    </a>
                  </Text> */
}

export default PrivacyPolicy;
