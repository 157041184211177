import React, { useState, useEffect } from 'react';
import Loader from "../Loader/Loader";
import Banner from '../Banner/Banner';
import Exclusive from '../Exclusive/Exclusive';
import How from '../How/How';
import Features from '../Features/Features';
import Dongle from '../Dongle/Dongle';
import Download from '../Download/Download';
import Testimonies from '../Testimonies/Testimonies';
import Layout from '../Layout/Layout';

const Home = () => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000)
    }, []);

    return (
        <div>
             {
                loading ?
                <Loader/>
                :
                <Layout >
                  <Banner />
                  <Exclusive/>
                  <How/>
                  <Features/>
                  <Dongle/>
                  <Download/>
                  <Testimonies/>
                </Layout>
            }
        </div>
    )
}

export default Home;
