import { useRef, useEffect } from "react";
import { gsap } from "gsap";
// import { useMediaQuery } from 'react-responsive';
// //import { DrawSVGPlugin } from "gsap/all";
import Styles from "./css/styles.module.css";
const Banner = () => {
    //const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
    const el = useRef();
    const q = gsap.utils.selector(el);
    const tl = useRef();

    useEffect(() => {
        tl.current = gsap.timeline()
        .from(q("#title"), {
            y: 100, duration:1.5, opacity:0, ease: "elastic", delay:1
        })
        .from(q("#paragraph"), {
            y: 100, duration:1, opacity:0, ease: "elastic", delay:1
        }, "-=1.5")
        .from(q("#img"), {
            x: 100, duration:1.5, opacity:0, ease: "ease-in", delay:1, scale:0.5
        }, "-=4");
    }, [q]);
    return (
        <section className={Styles.banner}>
            <div className="my-container">
                <div className={Styles.inner} ref={el}>
                    <div className={Styles.content}>
                        <h1 id="title" >The smartest way to care for your car.</h1>
                        <p id="paragraph">Stop the guesswork with FixBot you can track your car's health without you worrying about breakdowns anymore.</p>
                    </div>
                    <div className={Styles.imgCon}>
                        <img id="img" src="/images/Banner.png" alt="Banner" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner
