import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright} from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFacebook, faInstagram, faLinkedin, faMedium} from "@fortawesome/free-brands-svg-icons";
import { Link } from 'react-router-dom';
import Styles from "./css/styles.module.css"
const Footer = () => {
    return (
      <footer>
        <div className="my-container">
          <div className={Styles.inner}>
            <div className={Styles.foot_cont}>
              <div className={Styles.cont}>
                <div className={Styles.img_con}>
                  <img src="/images/bottom.svg" alt="Fixbotlogo" />
                </div>
                <div className={Styles.icons_con}>
                  <a href="https://twitter.com/FixBotHQ">
                    <FontAwesomeIcon className="icons" icon={faTwitter} />
                  </a>
                  <a href="https://www.facebook.com/FixbotHQ/">
                    <FontAwesomeIcon className="icons" icon={faFacebook} />
                  </a>
                  <a href="www.instagram.com/fixbothq">
                    <FontAwesomeIcon className="icons" icon={faInstagram} />
                  </a>
                  <a href="https://www.linkedin.com/company/fixbot-technologies/">
                    <FontAwesomeIcon className="icons" icon={faLinkedin} />
                  </a>
                  <a href="https://link.medium.com/YqxexfPJsnb">
                    <FontAwesomeIcon className="icons" icon={faMedium} />
                  </a>
                </div>
              </div>
              <div className={Styles.links_main}>
                <div>
                  <h1>Company</h1>
                  <p>
                    <Link to="*">About Us</Link>
                  </p>
                  <p>
                    <a href="https://blog.myfixbot.com/">Blog</a>
                  </p>
                  <p>
                    <Link to="*">Careers</Link>
                  </p>
                  <p>
                    <Link to="*">Legal & privacy</Link>
                  </p>
                  <p>
                    <Link to="*">Partners & Affiliates</Link>
                  </p>
                </div>

                <div>
                  <h1>Products</h1>
                  <p>
                    <Link to="*">How it Works</Link>
                  </p>
                  <p>
                    <Link to="*">Features</Link>
                  </p>
                  <p>
                    <Link to="*">Pricing</Link>
                  </p>
                </div>

                <div>
                  <h1>Support</h1>
                  <p>
                    <Link to="*">Contact us</Link>
                  </p>
                  <p>
                    <Link to="privacy-policy">Privacy</Link>
                  </p>
                  <p>
                    <Link to="*">Terms</Link>
                  </p>
                </div>
              </div>
            </div>
            <div className={Styles.rights}>
              <p>
                <FontAwesomeIcon className="cons" icon={faCopyright} /> 2022.
                FixBot Technologies Limited. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
}

export default Footer
