import React from "react";
import Styles from "./css/styles.module.css";
import { gsap} from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useEffect } from "react";
gsap.registerPlugin(ScrollTrigger);
const Features = () => {
    const headers = [
        {
            title: "Features"
        },
        {
            title: "FixBot"
        },
        {
            title: "Other Brands"
        },
    ]
    const features = [
        {
            id: 1,
            feauture: "Modern Vehicle Diagnostics",
            fixbot: "✔",
            others: "✔"
        },
        {
            id: 2,
            feauture: "Real-Time Data & Tracking",
            fixbot: "✔",
            others: "✔"
        },
        {   
            id: 3,
            feauture: "SOS Emergency Assistance",
            fixbot: "✔",
            others: "✔"
        },
        {
            id: 4,
            feauture: "Fuel Consumption Efficiency",
            fixbot: "✔",
            others: "✔"
        },
        {
            id: 5,
            feauture: "Access to Auto-parts Dealers",
            fixbot: "✔",
            others: "-"
        },
        {
            id: 6,
            feauture: "Access to mechanics nationwide",
            fixbot: "✔",
            others: "-"
        },
        {
            id: 7,
            feauture: "Attractive pricing",
            fixbot: "✔",
            others: "-"
        },
        {
            id: 8,
            feauture: "Dashboard Access for personal monitoring",
            fixbot: "✔",
            others: "-"
        },
    ]

    useEffect(() => {
        gsap.from("#feature #plain", {
            scrollTrigger: {trigger:"#plain", start:"top 90%", end:"bottom bottom" , toggleActions: "restart complete reverse reset"},
            y: 100, duration:1, opacity:0, ease: "ease-in"
        })
        gsap.from("#feature #unplain", {
            scrollTrigger: {trigger:"#unplain", start:"top 90%", end:"bottom 95%", toggleActions: "restart complete reverse reset"},
            y: 100, duration:1, opacity:0, ease: "ease-in"
        })
        gsap.from("#feature #deplane", {
            scrollTrigger: {trigger:"#deplane", start:"top 90%", end:"bottom 95%", toggleActions: "restart complete reverse reset"},
            y: 100, duration:1, opacity:0, ease: "ease-out"
        })
    }, []);

    return (
        <section className={Styles.features}>
            <div className="my-container">
                <div className={Styles.inner} id="feature">
                    <h1 id="plain">All the features you need...</h1>
                    <p id="unplain" className={Styles.para}>Our features beats other brand’s out of the market.</p>
                    <div id="deplane" className={Styles.cont}>
                            <div className={Styles.feature}>
                                {headers.map((title, index) => (
                                        <h3 key={index}>{title.title}</h3>
                                ))}
                            </div>
                            <div className={Styles.feature1}>
                                {features.map((feature, index) => (
                                    <React.Fragment key={index}>
                                        <p  className={Styles.left}>{feature.feauture}</p>
                                        <p className={Styles.green}>{feature.fixbot}</p>
                                        <p className={feature.others === "✔" ? Styles.green : Styles.red}>{feature.others}</p>
                                    </React.Fragment>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Features
