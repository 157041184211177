import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import Styles from "./css/styles.module.css"
import { gsap} from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useEffect } from "react";
import { useMediaQuery } from 'react-responsive';
gsap.registerPlugin(ScrollTrigger);
const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const data = [
      {
          id: 1,
          stars: 4,
          comment: `“As a lawyer, and as a mother, my day is constantly filled with
                    activities: both work and family related. With FixBot, I don’t have
                    to worry about my car breaking down on me, and I can
                    concentrate on other things. “`,
          img_src: "/images/Marshal.svg",
          name: "Marshall Odii",
          location: "ABUJA Nigeria"
      },
      {
        id: 2,
        stars: 5,
        comment: `“As a lawyer, and as a mother, my day is constantly filled with
                  activities: both work and family related. With FixBot, I don’t have
                  to worry about my car breaking down on me, and I can
                  concentrate on other things. “`,
        img_src: "/images/Marshal.svg",
        name: "Donald Odii",
        location: "KANO Nigeria"
    },
    {
        id: 3,
        stars: 3,
        comment: `“As a lawyer, and as a mother, my day is constantly filled with
                  activities: both work and family related. With FixBot, I don’t have
                  to worry about my car breaking down on me, and I can
                  concentrate on other things. “`,
        img_src: "/images/Marshal.svg",
        name: "Neche Odii",
        location: "LAGOS Nigeria"
    },
  ]

    const count = (value)  => {
        let arr = [];
       //console.log(typeof value)
        for(let i = 0; i < value; i++) {
            arr.push(i)
            //console.log(i)
        }
        //console.log(arr)
        return(arr)
    }

    
const Swiper = () => {
    const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
    useEffect(() => {
        if(isMobile) {
            gsap.from("#swiper", {
                scrollTrigger: {trigger:"#swiper", start:"top 90%", end:"bottom bottom", toggleActions: "restart complete reverse reset"},
                y: 100, duration:1, opacity:0, ease: "ease-out"
            }) 
        }else {
            gsap.from("#swiper", {
                scrollTrigger: {trigger:"#swiper", start:"top 90%", end:"bottom 90%", toggleActions: "restart complete reverse reset"},
                y: 100, duration:1, opacity:0, ease: "ease-out"
            })
        }
    }, [isMobile]);
    return (
        <div id="swiper">
             <Carousel className="carousel" 
                responsive={responsive}
                swipeable={true}
                draggable={true}
                showDots={true}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                >
                    {data.map((item, index) => (
                        <div key={index} className={Styles.swiper}>
                            <div className={Styles.rate_}>
                                {count(item.stars).map((number)=> (
                                    <div key={number}><FontAwesomeIcon key={number} className={Styles.con} icon={faStar} /></div>
                                ))}
                            </div>

                            <h4 className={Styles.comment_}>{item.comment}</h4>

                            <div className={Styles.individual_}>
                                <div className={Styles.imgCon_}>
                                    <img src={item.img_src} alt={item.img_src} />
                                </div>
                                <div className={Styles.name_loc}>
                                    <h5>{item.name}</h5>
                                    <span>{item.location}</span>
                                </div>
                            </div>
                        </div>
                    ))}

            </Carousel>
        </div>
    )
}

export default Swiper
