import Styles from './css/styles.module.css';
import { gsap} from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useEffect } from "react";
import { useMediaQuery } from 'react-responsive';
gsap.registerPlugin(ScrollTrigger);
const Download = () => {
    const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
    useEffect(() => {
        gsap.from("#download #download-1", {
            scrollTrigger: {trigger:"#download-1", start:"top 90%", end:"bottom bottom" , toggleActions: "restart complete reverse reset"},
            y: 100, duration:1, opacity:0, ease: "ease-in"
        })
        gsap.from("#download #download-3", {
            scrollTrigger: {trigger:"#download-3", start:"top 90%", end:"bottom bottom", toggleActions: "restart complete reverse reset"},
            y: 100, duration:1, opacity:0, ease: "ease-in", scale:0.5
        })
        if(isMobile){
            gsap.from("#download #download-2 a", {
                scrollTrigger: {trigger:"#download-2 a", start:"top 90%", end:"bottom 90%", toggleActions: "restart complete reverse reset"},
                y: 100, duration:1, opacity:0, ease: "ease-in", stagger: 0.5
            })
        }else {
            gsap.from("#download #download-4 a", {
                scrollTrigger: {trigger:"#download-4 a", start:"top 90%", end:"bottom bottom", toggleActions: "restart complete reverse reset"},
                y: 100, duration:1, opacity:0, ease: "bounce", stagger: 0.5
            })
        }
    }, [isMobile]);
    return (
        <section className={Styles.download}>
            <div className='my-container'>
                <div className={Styles.inner} id='download'>
                    <div className={Styles.content}>
                        <h1 id='download-1'>We’re coming soon on your device!</h1>
                        <div className={Styles.links__m} id='download-2'>
                            <a href="/"><img src="/images/goggleplay.svg" alt="goggle playstore link" /></a>
                            <a href="/"><img src="/images/applestore.svg" alt="Apple store link" /></a>
                        </div>
                    </div>
                    <div className={Styles.imgCon}>
                        <div className={Styles.img_}>
                            <img src="/images/cat.png" id='download-3' alt="Fixbot" />
                        </div>
                        <div className={Styles.links__} id='download-4'>
                            <a href="/"><img src="/images/goggleplay.svg" alt="goggle playstore link" /></a>
                            <a href="/"><img src="/images/applestore.svg" alt="Apple store link" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Download
