import Styles from "./css/styles.module.css";
import { gsap} from "gsap";
import axios from 'axios';
import { ScrollTrigger } from "gsap/all";
import { useEffect, useRef, useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from "react-router-dom"

gsap.registerPlugin(ScrollTrigger);
const Exclusive = () => {
    const navigate = useNavigate();
    useEffect(() => {
        gsap.from("#pink #exclude", {
            scrollTrigger: {trigger:"#exclude", start:"top 90%", end:"bottom bottom" , toggleActions: "restart complete reverse reset"},
            y: 100, duration:1, opacity:0, ease: "ease-in"
        })
        gsap.from("#pink #exclude-para", {
            scrollTrigger: {trigger:"#exclude-para", start:"top 90%", end:"bottom 95%", toggleActions: "restart complete reverse reset"},
            y: 100, duration:1, opacity:0, ease: "ease-in"
        })
        gsap.from("#pink #form-suscribe", {
            scrollTrigger: {trigger:"#form-suscribe", start:"top 90%", end:"bottom 95%", toggleActions: "restart complete reverse reset"},
            y: 100, duration:1, opacity:0, ease: "ease-out"
        })
    }, []);

    const emailRef = useRef();
    const form = useRef(null);
    const [isPending, setIsPending] = useState(false);
    const [loading] = useState(true);
    const [size] = useState(10);

    const submitData = (e) => {
        e.preventDefault();
        setIsPending(true);
        const formDate = new FormData();

        const data = {
            Email: emailRef.current.value
        }

        for (const key in data) {
            formDate.append(key, data[key]);
        }

        // axios({
        //     method: 'post',
        //     url: `https://v1.nocodeapi.com/reavel/google_sheets/MHuqtsKlOTIoqrHU?tabId=Sheet1`, 
        //     params: {},
        //     data: [[emailRef.current.value]]
        // })

        axios.post('https://script.google.com/macros/s/AKfycbzmO4PL2czFUNyLfEXjmFKHUpav0vTj5InvTTdfdIN2cXlq5VDrCPCbPeyMviPi9Ubs/exec', formDate, { headers: { 'Content-Type': `multipart/form-data` } })
        .then(response => {
            //console.log(response);
            setIsPending(false);
            form.current.reset(); 
            toast.success(response.data.result);
            setTimeout(() => { navigate('/signup') }, 3000)
        })
        .catch(error => {
            console.log(error);
            toast.error(error.message);
            if(error.name === 'AbortError') {
                toast.error('Email not sent')
            }else {
                setIsPending(false);
            }
        });
        return false;
    }


    return (
        <section className={Styles.exclusive}>
            <Toaster  position="top-right" />
            <div className="my-container">
                <div className={Styles.inner} id="pink">
                    <div>
                        <h1 id="exclude">Get an exclusive discount!</h1>
                        <p id="exclude-para">We're launching soon! Get an exclusive discount by signing up now!</p>
                        {isPending && <PulseLoader color={'#072F5F'} loading={loading} size={size} />}
                        <form id="form-suscribe" ref={form} onSubmit={(event) => submitData(event)} encType="multipart/form-data">
                            <input type="email" name="email" ref={emailRef} className={Styles.email} placeholder="Email address" required/>
                            <input type="submit" className={Styles.submit} value="Sign up" />
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Exclusive
